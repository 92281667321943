.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .titleRow > p {
  margin-bottom: 5px;
}



.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow.additionalQuestion {
  border: 0;
  margin-bottom: 30px;
}
.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow.additionalQuestion > .contentRow {
  display: flex;
  flex-direction: column;
}
.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow.gSaveButton {
  margin-top: 20px;
}
.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .contentBox {
  width: 100%;
  width: calc(100% - 20px);
}
.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .contentBox > .help {
  font-size: 13px;
  margin-top: 3px;
}

.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .inputTypeBox {
}
.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .inputTypeBox > .rw {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .inputTypeBox > .rw > input {
  margin-right: 5px;
}
.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .inputErrorBox {
  color: var(--generic-error-color);
  font-size: var(--font-size-sub-mid-primary);
  font-weight: var(--font-weight-error);
  margin-top: 5px;
}
.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow.gErrRw {
  color: var(--generic-error-color);
  font-size: var(--font-size-sub-mid-primary);
  font-weight: var(--font-weight-error);
  margin-top: 5px;
}

.gPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .titleRow > p {
  margin-bottom: 5px;
}


.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap {
  display: flex;
  flex-direction: column;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .devicesBox {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .devicesBox > span {
  border: 1px solid #e6e6e7;
  padding: 10px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .labelValueBox {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .labelValueBox > .label {
  width: 100px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .labelValueBox > .value {
  flex: 1;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .labelValueBox > .value.highlight {
  background-color: yellow;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox {
  display: flex;
  flex-direction: column;
  border-top: 1px solid grey;
  margin-top: 20px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap.positiveFeedback > .emailPreviewBox > .emailBodyBox {
  border-top: 0;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .headerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .headerBox > img {
  width: 300px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .headerBox > p {
  font-size: 18px;
  margin-top: 20px;
}

.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .positiveTitle {
  margin-bottom: 20px;
  font-size: 16px;
}

.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .salBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .salBox > .guestSalutation {
  margin-bottom: 20px;
  font-size: 16px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .salBox > .guestSalutation.highlight {
  background-color: yellow;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .title {
  margin-bottom: 20px;
  font-size: 16px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .title.highlight {
  background-color: yellow;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .primaryMessage {
  margin-bottom: 20px;
  font-size: 14px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .primaryMessage.highlight {
  background-color: yellow;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox  > textarea {
  resize: none;
  width: 50%;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p {
  font-size: 12px;
  margin-bottom: 4px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p.hotelMessage {
  margin-bottom: 20px;
  font-size: 14px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p.hotelMessage.highlight {
  background-color: yellow;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p.unsubscribe {
  margin-top: 50px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p.poweredBy {
  margin-top: 30px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.gPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p > span.link {
  color: blue;
  font-size: 12px;
  cursor: pointer;
}

/* smiley */

.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .moodBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* stars */

.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars {
  display: flex;
  gap: 10px;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars > .starIcon {
  height: 30px;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars > .star {
  flex: 25%;
  margin: 10px 0;
  position: relative;
  display: inline-block;
  color: red;
  width: 0px;
  height: 0px;
  border-right: 50px solid transparent;
  border-bottom: 33px solid gold;
  border-left: 50px solid transparent;
  transform: rotate(35deg) scale(1);
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars > .star:before {
  border-bottom: 40px solid gold;
  border-left: 15px solid transparent;
  border-right: 25px solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -25px;
  left: -30px;
  display: block;
  content: '';
  transform: rotate(-32deg);
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars > .star:after {
  position: absolute;
  display: block;
  color: red;
  top: 0;
  left: -50px;
  width: 0;
  height: 0;
  border-right: 50px solid transparent;
  border-bottom: 35px solid gold;
  border-left: 50px solid transparent;
  transform: rotate(-70deg);
  content: '';
}

/* nps */

.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .npsBox {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .npsBox > .top {
  display: flex;
  flex-direction: row;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .npsBox > .top > .npsNumber {
  margin: 0 4px;
  text-decoration: none;
  padding: 6px 8px;
  border: 1px solid orange;
  border-radius: 5px;
  cursor: pointer;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .npsBox > .bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

/* additonal question stars */
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .additionalQuestion > .starBox {
  margin-bottom: 20px;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .additionalQuestion > .starBox > .stars {
  display: flex;
  gap: 10px;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .additionalQuestion > .starBox > .stars > .starIcon {
  height: 30px;
}


.gPg.fdbPosPg > .innerBox > .topBox > .tabBox {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
}
.gPg.fdbPosPg > .innerBox > .topBox > .tabBox > a {
  font-weight: var(--font-weight-bold);
  color: var(--sidebar-text-color);
}
.gPg.fdbPosPg > .innerBox > .topBox > .tabBox > a.active {
  color: var(--gte-primary-color);
  border-bottom: 2px solid var(--sidebar-text-color);;
}
