.gPg.fdbCustTypPg > .innerBox > .contentBox > .typeBoxWrap {
  padding: 10px;
  display: grid;
  display: flex;
  flex-direction: row;
  g1rid-template-columns: auto auto auto auto;
  gap: 10px 10px;
  padding-bottom: 50px;
  m1ax-height: 400px;
  o1verflow-y: scroll;
}

.typeBoxWrap > .typeBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px rgba(37, 39, 52, 0.1);
  padding: 20px;
}
.typeBoxWrap > .typeBox > .sampleLink {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.typeBoxWrap > .typeBox > .sampleLink > span {
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
  cursor: default;
}
.typeBoxWrap > .typeBox > .sampleLink > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.typeBoxWrap > .typeBox > .top,
.typeBoxWrap > .typeBox > .body,
.typeBoxWrap > .typeBox > .select {
  display: flex;
  flex-direction: column;
}

.typeBoxWrap > .typeBox > .top {
  margin-top: 10px;
}
.typeBoxWrap > .typeBox > .top > .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  text-align: center;
  margin-top: 20px;
}
.typeBoxWrap > .typeBox > .top > .iconRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.typeBoxWrap > .typeBox > .top > .iconRow > img {
  height: 30px;
  margin: 0 10px;
}

.typeBoxWrap > .typeBox > .body {
  margin-top: 20px;
}
.typeBoxWrap > .typeBox > .body > .desc {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-normal);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  text-align: center;
}

.typeBoxWrap > .typeBox > .select {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.typeBoxWrap > .typeBox > .select > p {
  color: var(--gte-primary-color);
  font-size: var(--font-size-primary);
  font-weight: 600;
}
.typeBoxWrap > .typeBox > .select > span {
  display: flex;
  justify-content: center;
  padding: 10px 50px;
  border-radius: var(--generic-border-radius);
  background-color: grey;
  color: #FFFFFF;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  cursor: pointer;
  border: 1px solid grey;
  width: 120px;
}
.typeBoxWrap > .typeBox.active > .select > span {
  background-color: #0d43aa;
  border: 1px solid var(--gte-primary-color);
  cursor: default;
}
.typeBoxWrap > .typeBox.selected > .select > span {
  background-color: #0d43aa;
  border: 1px solid var(--gte-primary-color);
}
.typeBoxWrap > .typeBox > .select > span > span {
  margin-left: 8px;
}
