.glW {
  width: 350px;
  height: 100%;
  border-right: 1px solid var(--generic-border-color);
  display: flex!important;
  flex-direction: column;
  position: relative!important;
  transform: none!important;
}
.gPnl {
  flex: 1;
  position: relative;
}
.selConv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.glW > .rszIndc {
  position: absolute;
  right: 0;
  top: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  gap: 1px;
  color: var(--resize-icon-color);
}
.glW > .rszIndc > span {
  line-height: 1;
}
.glW > .hdr {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.glW > .optB {
  height: 60px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.glW > .glB {
  height: calc(100% - 60px - 60px);
  overflow-y: scroll;
}
.glW > .glB::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #ffffff;
}
.glW > .glB::-webkit-scrollbar
{
  height: 6px;
	width: 5px;
	background-color: #ffffff;
}
.glW > .glB::-webkit-scrollbar-thumb
{
  o1utline: 1px solid slategrey;
	border-radius: 10px;
	background-color: #BDBEC1;
}


.glW > .hdr > .ttl {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.glW > .hdr > .ttl > h3 {
  margin-bottom: 0;
}
.glW > .hdr > .ttl > img {
  height: 16px;
}

.glW > .optB > .filter {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.glW > .optB > .filter > img.fIcon {
  margin-left: 10px;
  z-indeX: 2;
}
.glW > .optB > .filter > input {
  width: 100%;
  padding: 5px;
  padding-left: 32px;
  padding-right: 30px;
  margin-left: -30px;
  margin-right: -20px;
  border-radius: 5px;
  border: 1px solid var(--generic-border-color);
}
.glW > .optB > .filter > img.cIcon {
  p1osition: absolute;
  m1argin-top: 10px;
  m1argin-left: 178px;
}

.glW > .optB > .sort {
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

}

.glB > .glR {
  display: flex;
  flex-direction: column;
  color: var(--primary-text-color);
  text-decoration: none;
  padding: 20px;
  border-bottom: 1px solid var(--generic-border-color);
  b1order-top: 1px solid var(--generic-border-color);
}
.glB > .glR.active {
  background-color: var(--sidebar-main-menu-active-bg);
}
.glB > .glR:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.glB > .glR.rplReq {
  padding-top: 10px;
}
.glB > .glR > .rw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.glB > .glR > .rw.aiAlrts {
  margin-bottom: 10px;
}
.glB > .glR > .rw.aiAlrts > .lt.convRt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.glB > .glR > .rw.aiAlrts > .lt.convRt > span {
  padding: 2px 10px;
  background-color: #43ef11;
  border-radius: var(--generic-border-radius);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  cursor: default;
}
.glB > .glR > .rw.aiAlrts > .rt.rplReq {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.glB > .glR > .rw.aiAlrts > .rt.rplReq > span {
  padding: 2px 4px;
  background-color: #c8d3fc;
  border-radius: var(--generic-border-radius);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  cursor: default;
}
.glB > .glR > .rw.cnclRw {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.glB > .glR > .rw.cnclRw > .cnclLbl {
  color: var(--generic-error-color);
  font-weight: var(--font-weight-bold);
  padding: 2px 4px;
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--generic-error-color);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
}
.glB > .glR > .rw.name {
  font-weight: var(--font-weight-bold);
  color: var(--primary-text-color);
  margin-bottom: 5px;
}
.glB > .glR > .rw.name > .rt {
  color: var(--primary-text-color-light);
  font-weight: normal;
}

.glB > .glR > .rw.lblsRw {
  margin-bottom: 5px;
}
.glB > .glR > .rw.lblsRw > .lt {
  display: flex;
  flex-direction: row;
}
.glB > .glR > .rw.lblsRw > .lt > .entry {
  padding: 2px 4px;
  background-color: #c8d3fc;
  border-radius: var(--generic-border-radius);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  margin-right: 5px;
  margin-bottom: 5px;
}

.glB > .glR > .rw.msg {
  margin-bottom: 5px;
}
.glB > .glR > .rw.msg > .lt {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  width: calc(100% - 50px);
  color: var(--primary-text-color-light);
}
.glB > .glR > .rw.msg > .lt > i {
  margin-right: 5px;
}
.glB > .glR > .rw.msg > .lt > img {
  margin-right: 5px;
}
.glB > .glR > .rw.msg > .rt > .uc {
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
  border-radius: 10px;
  p1adding: 2px 7px;
  font-size: 10px;
  position: relative;
  t1op: -11px;
  r1ight: 14px;
  height: 18px;
  min-width: 18px;
  b1ackground-color: #ba73f1;
  color: #FFFFFF;
  border-radius: 10px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.glB > .glR > .rw.pg {
  color: var(--primary-text-color-light);
}
.glB > .glR > .rw.pg.rm {
  margin-bottom: 5px;
}



/* ************** guest list - settings - dropdown menu ********************** */

.sts > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.sts > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99;
}
.sts > .dropdown > .dropdown-content {
  left: 0px;
}

.sts > .dropdown > .dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: default;
}

.sts > .dropdown > .dropdown-content p:hover {background-color: var(--generic-hover-bg-color);}

.sts > .dropdown.open .dropdown-content {display: block;}

.sts > .dropdown > .dropbtn {
  padding: 4px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  display: flex;
  flex-direction: row;gap: 3px;
}
.sts > .dropdown > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);
}

/* ************************************ */


/* ************** guest list - settings - dropdown menu ********************** */

.sort > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.sort > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99;
}
.sort > .dropdown > .dropdown-content {
  left: 0px;
}

.sort > .dropdown > .dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: default;
}

.sort > .dropdown > .dropdown-content p:hover {background-color: var(--generic-hover-bg-color);}

.sort > .dropdown.open .dropdown-content {display: block;}

.sort > .dropdown > .dropbtn {
  padding: 5px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  display: flex;
  flex-direction: row;gap: 3px;
}
.sort > .dropdown > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);
}

/* ************************************ */

.sdMdl.gLDispPref > .ctnt > .gPWrp > .gPTle.title {
  padding-bottom: 0;
}
.sdMdl.gLDispPref > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.valRw {
  margin-bottom: 10px;
}
.sdMdl.gLDispPref > .ctnt > .gPWrp > .gBCtnt > .gBCtRw > .gRwBxLt > .gLbl > label > input {
  margin-right: 10px;
}
