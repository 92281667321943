.gLstWrp.jMLst > .gLstBd > .gLstRw {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: var(--sidebar-text-color);
}
.gLstWrp.jMLst > .gLstBd > .gLstRw.active {
  color: var(--gte-primary-color);
}
.gLstWrp.jMLst > .gLstBd > .gLstRw > .lt {
  width: 50px;
}
.gLstWrp.jMLst > .gLstBd > .gLstRw > .rt {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-primary);
}
.gLstWrp.jMLst > .gLstBd > .gLstRw > .rt > .tp {
  font-weight: var(--font-weight-bold);
}
.gLstWrp.jMLst > .gLstBd > .gLstRw > .rt > .bt {
  font-weight: normal;
}

.gLstWrp.jMLst > .gLstBd > .gLstRw > .rt > .tp > span.def {
  margin-left: 5px;
}
